<template>
  <div :class="containerClassList" :hidden="hidden">
    <label v-if="label">{{ label }}</label>
    <div :class="fieldClassList" @click="toggleDropdown" ref="select-ref">
      <input
        :class="inputClassList"
        :placeholder="placeholder"
        :value="renderedValue"
        :ref="inputRef"
        disabled
      />

      <div class="csn-select__icon">
        <DropdownArrow />
      </div>

      <div
        class="csn-select__list"
        :ref="dropdownListRef"
        :class="{ 'csn-select__list--above': isListAbove }"
      >
        <div v-for="item in renderedList" :key="item.id">
          <div
            :id="item.id"
            class="csn-select__list-item"
            @click.stop="selectOption($event, item)"
          >
            {{ item[nameField] }}
          </div>
        </div>
      </div>
    </div>
    <Balloon :text="message" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  EventType,
  INPUT_CONTAINER_CLASS,
  EMPTY_STRING,
  DROPDOWN,
  EMPTY_OBJECT,
  IS_MOBILE_SCREEN,
  Module,
  CSN_INPUT_CLASS,
} from '@/constants'
import {
  generateUUID,
  hasDuplicateValues,
  isPromise,
  isPlainObject,
  isObjectArray,
  isArrayOfPrimitives,
  filterOutNil,
  indexBy,
  map,
  pipe,
  prop,
  values,
  ifElse,
  when,
  dest,
} from '@/helpers'
import { inputMixin } from '@/mixins'

const DEFAULT_DISPLAY_NAME_FIELD_NAME = 'name'
const DEFAULT_DISPLAY_VALUE_FIELD_NAME = 'value'
const DROPDOWN_LIST_REF = 'dropdown-list'
const INPUT_REF = 'input'
const SELECT_CLASS = 'form-control form-control csn-select__input'
const SELECT_CONTAINER_CLASS = 'csn-dropdown'
const CSN_SELECT = 'csn-select'
const CSN_SELECT__OPEN = 'csn-select--open'
const SELECT_ORDER = 'selectOrder'

const ErrMsg = {
  hasWrongFormat:
    'dataSource must be an array which does not contain objects and primitives together.',
  hasDuplicateValuesError: 'All dataSource values must be unique.',
}

const isElementBottomVisible = (target) => {
  const targetPositionBottom =
      window.pageYOffset + target.getBoundingClientRect().bottom,
    windowPositionBottom =
      window.pageYOffset + document.documentElement.clientHeight

  return windowPositionBottom > targetPositionBottom
}
const throwErrorIfDuplicateValues = when(hasDuplicateValues, () => {
  throw new Error(ErrMsg.hasDuplicateValuesError)
})
const throwHasWrongFormatError = () => {
  throw new Error(ErrMsg.hasWrongFormat)
}

/**
 * Creates Dropdown component
 * @param {string} placeholder is text which will be used as placeholder.
 * @param {string} label is text which will be used as label.
 * @param {string | object} value is added by v-model.
 * @param {string[] | object[] | promise} dataSource is a list of items or promise that return list of items for select;
 * The items must be either string or objects.
 * If the items are objects, the values of their fields that will be used as "displayValue" must be unique;
 *
 * The following two are needed if dataSource is object array:
 * @param {string} displayName is the field name of list item object that will be rendered as dropdown option name.
 * If it is not specified it will be "name" property by default.
 * @param {string} displayValue is the field name of list item object that will be returned.
 *                              Each dataSource object item must have this field with a unique value.
 * If it is not specified it will be "value" property by default.
 * If dataSource is array of string then displayName and displayValue will use the same string array item values.
 **/

export default {
  name: DROPDOWN,
  inheritAttrs: false,
  components: {
    Balloon: () => import('@/components/Balloon'),
    DropdownArrow: () => import('@/components/svg/DropdownArrow'),
  },
  mixins: [inputMixin],
  props: {
    value: {
      type: [String, Object, Number],
      default: EMPTY_STRING,
    },
    dataSource: {
      type: [Array, Promise],
      default: null,
    },
    displayName: {
      type: String,
      default: EMPTY_STRING,
    },
    displayValue: {
      type: String,
      default: EMPTY_STRING,
    },
    placeholder: {
      type: String,
      default: EMPTY_STRING,
    },
    label: {
      type: String,
      default: EMPTY_STRING,
    },
    selectClass: {
      type: String,
      default: EMPTY_STRING,
    },
    keepsOrder: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOpen: false,
    isListAbove: false,
    byDisplayValueDictionary: null,
    selectedIndex: null,
  }),
  computed: {
    ...mapGetters({ isMobileScreen: dest([Module.DEVICE, IS_MOBILE_SCREEN]) }),
    nameField() {
      return this.displayName || DEFAULT_DISPLAY_NAME_FIELD_NAME
    },
    valueField() {
      return this.displayValue || DEFAULT_DISPLAY_VALUE_FIELD_NAME
    },
    renderedList() {
      return values(this.byDisplayValueDictionary || EMPTY_OBJECT)
    },
    renderedValue() {
      const dictionary = this.byDisplayValueDictionary
      const index = this.keepsOrder ? this.selectedIndex : this.value
      const name = this.nameField

      return dictionary?.[index]?.[name] || EMPTY_STRING
    },
    containerClassList() {
      return [
        INPUT_CONTAINER_CLASS,
        SELECT_CONTAINER_CLASS,
        this.containerClass,
      ]
    },
    fieldClassList() {
      const openClass = this.isOpen ? CSN_SELECT__OPEN : EMPTY_STRING

      return [CSN_SELECT, openClass, this.selectClass]
    },
    inputClassList() {
      return [SELECT_CLASS, CSN_INPUT_CLASS, this.inputClass]
    },
    dropdownListRef: () => `${DROPDOWN_LIST_REF}${generateUUID()}`,
    inputRef: () => `${INPUT_REF}${generateUUID()}`,
  },
  watch: {
    dataSource: {
      immediate: true,
      handler(value) {
        this.formatList(value)
      },
    },
    isOpen(value) {
      if (value) {
        this.$nextTick(() => {
          if (!this.isListAbove && this.isMobileScreen) {
            return
          }

          const list = this.$refs[this.dropdownListRef]
          this.isListAbove = !isElementBottomVisible(list)
        })
      }
    },
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen
      !this.isMobileScreen &&
        !this.isOpen &&
        (this.isListAbove = !this.isListAbove)
    },
    selectOption(event, option) {
      const value = option[this.valueField]

      this.keepsOrder && (this.selectedIndex = option[SELECT_ORDER])
      this.toggleDropdown()
      this.$emit(EventType.INPUT, value)
      this.$emit(EventType.CHANGE, { event, value })
    },
    handleClick({ target }) {
      const { $el } = this

      if (!$el.contains(target)) {
        this.isOpen = false
        return
      }

      this.$refs[this.inputRef].focus()
    },
    formatSelectItem(item, index) {
      const id = item?.id || generateUUID()
      const order = this.keepsOrder ? { [SELECT_ORDER]: index } : EMPTY_OBJECT

      return isPlainObject(item)
        ? { ...item, ...order, id }
        : { [this.nameField]: item, [this.valueField]: item, ...order, id }
    },
    setSelectList(list) {
      ifElse(
        isArrayOfPrimitives,
        throwErrorIfDuplicateValues,
        ifElse(
          isObjectArray,
          pipe(map(prop(this.valueField)), throwErrorIfDuplicateValues),
          throwHasWrongFormatError,
        ),
      )(list)

      const index = this.keepsOrder ? SELECT_ORDER : this.valueField

      this.byDisplayValueDictionary = pipe(
        filterOutNil,
        (list) => list.map(this.formatSelectItem),
        indexBy(index),
      )(list || EMPTY_OBJECT)
    },
    formatList(dataSource) {
      isPromise(dataSource)
        ? dataSource.then(this.setSelectList).catch(console.error)
        : this.setSelectList(dataSource)
    },
  },
  mounted() {
    document.addEventListener(EventType.CLICK, this.handleClick)
  },
  beforeDestroy() {
    document.removeEventListener(EventType.CLICK, this.handleClick)
  },
}
</script>
